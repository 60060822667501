import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { getNotableTitles } from '../../../services/studies';
import styles from './notableTitles.css';

export default function NotableTitles() {
  const [notableStudies, setNotableStudies] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const notableRef = useRef(null);

  useEffect(() => {
    getNotableTitles()
      .then(res => setNotableStudies(res))
      .catch(err => console.log(err));
  }, []);

  const handleShowMore = () => {
    setShowMore(!showMore);
    notableRef.current.scrollIntoView();
  };

  const titles = notableStudies.map(study => (
    <Link key={study.summary_title} to={`/shared?uuid=${study.uuid}&docType=${study.doc_type}`} target='_blank'>
      <p className={styles.notableTitle}>
        {study.summary_title}
      </p>
    </Link>
  ));

  return (
    <section id='notable-titles' role="complementary" aria-label="notable studies" className={styles.notableContainer} ref={notableRef}>
      <h4>
        Notable Studies
      </h4>
      <div className={styles.titlesContainer}>
        <a href="/2022studies" style={{ textDecoration: 'underline', margin: '10px auto 5px auto' }}>🔗 New and clinically relevant studies from 2022</a>
        {/* <Link to='/studies?notable=true' className="textLink" style={{ backgroundColor : 'white' }}>
          See More Info
        </Link> */}
        {showMore ? titles : titles.slice(0, 3)}
        <input aria-label={showMore ? 'Show Less' : 'Show More'} type='button' onClick={handleShowMore} value={showMore ? 'Show Less' : 'Show More'}/>
      </div>
    </section>
  );
}
