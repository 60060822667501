import React from 'react';
import { FaTwitter } from 'react-icons/fa';

const TwitterIcon = () => (
  <a href="https://www.twitter.com/knowoncology/" target="_blank" rel="noopener noreferrer">
    <FaTwitter color="#1DA1F2" size="24px" />
  </a>
);

export default TwitterIcon;
