import React from 'react';

const containerStyle = {
  width: '95%',
  height: '90%',
  borderRadius: '8px',
  boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.2)',
  margin: '20px auto 20px auto',
  padding: '16px'
};

const h1Style = {
  fontWeight: '600',
  textDecoration: 'underline',
  marginBottom: '12px'
};

const h2Style = {
  fontWeight: '600',
  textDecoration: 'underline',
  margin: '15px 0px 10px 0px'
};

export default function Studies2022() {
  return (
    <div style={containerStyle}>
      
      <h1 style={h1Style}>Get in the KNOW: New and clinically relevant studies from 2022</h1>
      
      <h2 style={h2Style}>Immune Checkpoint Inhibitors</h2>
      
      <p>
        Probiotics: In an RCT, 30 people with metastatic renal cancer were given oral 
        Clostridium butyricum MIYAIRI 588 (CBM588) probiotic or placebo 
        alongside nivolumab and ipilimumab. The CBM588 supplemented group had better 
        progression free survival (12.7 months vs 2.5 months). Although not statistically significant, the response rate was also higher in patients receiving probiotics (58% versus 20%). There was no significant difference in 
        Bifidobacterium species. or Shannon index associated with the addition of CBM588 to nivolumab–ipilimumab. Incidence of grade 3 or 4 toxicities were the same in both groups.  
      </p>
      
      <p>
        <a href="https://knowintegrativeoncology.org/shared?uuid=dead790f-8e01-4112-b0d2-ebf320a06523&docType=None" target='_blank' rel='noopener noreferrer' target='_blank' rel='noopener noreferrer'>
          🔒🔗 KNOW Summary
        </a>
      </p>
      <br/>
      <p>
        Beta glucans: In a pilot study of 13 patients with advanced cancer who had progressive disease after immune checkpoint blockade treatment, the use of beta-glucans 1000 mg daily during re-treatment with anti-PD-1/PD-L1 therapy may have supported improved progression free or overall survival. This study was small and not controlled, so it did not have enough power to determine an overall effect on survival.
      </p>
      
      <p>
        <a href="https://knowintegrativeoncology.org/shared?uuid=88f8f890-191f-4849-a941-b3b196434d16&docType=Summary" target='_blank' rel='noopener noreferrer'>
          🔒🔗 KNOW Summary
        </a>
      </p>
      
      <h2 style={h2Style}>COVID Related</h2>
      
      <p>
        In two case reports of people with cancer who experienced a sharp decline in their health while in the ICU for treatment with concomitant COVID-19, one study reported marked improvement after starting adjunctive treatment with intravenous vitamin C, 12 g twice daily for 7 days, and the other study reported benefit with oral supplementation of 1050 mg of silymarin daily.
      </p>

      <p>
        <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8952434/" target='_blank' rel='noopener noreferrer'>
         🔗 Pubmed
        </a>
      </p>

      <p>
        <a href="https://www.mdpi.com/2072-6694/14/7/1756" target='_blank' rel='noopener noreferrer'>
        🔗 Open Access
        </a>
      </p>
      
      <h2 style={h2Style}>Mucositis</h2>
      <p>
        Chamomile: In a systematic review of 492 people from 6 randomized controlled trials, topical chamomile was effective in the prevention and/or treatment of mucositis in 4 of the 6 studies. In all studies, chamomile was used as a mouthwash or mouthwashes frozen into ice chips, with dosing frequency ranging from 1-4 times daily. Five clinical trials were on chamomile for chemotherapy-induced mucositis and one on radiation-induced mucositis. In the two trials which failed to show significant benefit, one trial compared chamomile mouthwash to ice chips during 5-FU chemotherapy, with ice chips providing better pain relief. One study compared chamomile to honey for radiation induced mucositis and honey was superior. The overall quality of evidence was high.
      </p>
      <p>
        <a href="https://knowintegrativeoncology.org/shared?uuid=695ec2e6-8267-4eec-ae29-4105ef4e4994&docType=Microtag" target='_blank' rel='noopener noreferrer'>
          🔒🔗 KNOW Microtag
        </a>  
      </p>
      <br/>
      <p> 
        Probiotics: In a systematic review of 
        708 people from 8 randomized controlled trials, probiotics helped to prevent and treat mucositis. Overall incidence of oral mucositis was 81.5% in the probiotic group and 96.3% in the control group (p = 0.0004) while the incidence of severe oral mucositis was 34.3% and 56.6% in the probiotic group and the control group (p &lt; 0.0001). Three trials used a combination of lactobacillus spp. and 
        Bifidobacterium spp. Four trials used probiotics containing only lactobacillus spp. One trial used 
        Bacillus clausii. Six trials included people with head and neck cancers and two trials included people with colorectal cancer. Overall quality of trials was mixed. Note that while probiotics were beneficial, other therapies recommended by MASCC such as honey, glutamine and photobiomodulation therapy had even greater treatment effects for reducing incidence of severe mucositis.  
      </p>
      <p>
        <a href="https://knowintegrativeoncology.org/shared?uuid=9aabc294-6e3d-4f4f-a769-cb5b119b419e&docType=None" target='_blank' rel='noopener noreferrer'>
          🔒🔗 KNOW Summary
        </a>
      </p>
      <br/>
      <p>
        Pomegranate seed oil added to routine mouthwash: In an RCT, 70 people with mucositis from chemotherapy received routine mouthwash or 50% routine mouthwash with 50% cold pressed pomegranate seed oil, 15 ml 4 times daily for 14 days. The pomegranate seed oil group had lower rates and less severe mucositis after 7 and 14 days  (P&lt;0.001).
      </p>
      <p>
        <a href="https://knowintegrativeoncology.org/shared?uuid=b93383bf-4c5a-44d0-9504-78f1ce99a905&docType=Summary" target='_blank' rel='noopener noreferrer'>
          🔒🔗 KNOW Summary
        </a>
      </p>
      <br/>
      <p>
        Resveratrol-copper: In a controlled trial, the 20 out of 25 people with multiple myeloma being treated with high dose melphalan who took resveratrol-copper had less grade 3/4 mucositis than the 5 people who were controls (40% vs 100%).  People taking the  lower dose (resveratrol 5.6 mg, copper 560 ng) had lower salivary inflammation while people taking the high dose did not (resveratrol 50 mg, copper 5 μg).
      </p>
      <p>
        <a href="https://knowintegrativeoncology.org/shared?uuid=3a71183b-aa90-46c6-a85f-f7157e0d2df0&docType=Microtag" target='_blank' rel='noopener noreferrer'>
          🔒🔗 KNOW Microtag
        </a>
      </p>

      <h2 style={h2Style}>Radiation Dermatitis</h2>
      <p>
        In a double blind RCT involving 120 people with head and neck cancer undergoing chemoradiation with cisplatin or carboplatin, the use of aloe vera in the morning and evening improved skin-related radiation effects such as redness, itching, burning sensation, and moist desquamation.
      </p>
      <p>
        <a href="https://knowintegrativeoncology.org/shared?uuid=b9ed85e4-1015-4838-ad2b-ca3477c7cb8a&docType=None" target='_blank' rel='noopener noreferrer'>
          🔒🔗 KNOW Summary
        </a>
      </p>
      <br/>
      <p>
        In a double blind RCT involving 165 women undergoing radiation therapy for breast cancer, the use of topical green tea solution on the skin three times per day in the radiation field resulted in significantly less frequent and less severe radiation dermatitis. This study reported that the women using topical green tea on the skin during radiation had reduced severity of burning feeling, itching, pain and tenderness compared to control group patients who were applying topical saline in a similar manner.
      </p>
      <p>
        <a href="https://knowintegrativeoncology.org/shared?uuid=f93de20e-cd3d-4e29-8e56-5f31e07d4db4&docType=None" target='_blank' rel='noopener noreferrer'>
          🔒🔗 KNOW Summary
        </a>
      </p>
      <br/>
      <p>
        In a triple blinded RCT, 42 women with breast cancer received either 
        80 mg/day nano-curcumin capsules or placebo once daily during radiation and for two weeks post radiation. The nano-curcumin supplement did not significantly reduce the dermatitis severity during the first to sixth weeks (P &gt; 0.05) however, there was a significant difference at week 7 (P = 0.01). The patient-reported pain was significantly reduced in the treatment group compared with the control group (P &lt; 0.05).
      </p>
      <p>
        <a href="https://knowintegrativeoncology.org/shared?uuid=09ee554a-fdd9-4a02-b62e-ca429e2f3f5b&docType=None" target='_blank' rel='noopener noreferrer'>
          🔒🔗 KNOW Summary
        </a>
      </p>

      <h2 style={h2Style}>Surgical Support</h2>
      <p>
        Probiotics: In a systematic review and meta-analysis of 1776 people with gastrointestinal cancers from 21 randomized controlled trials, the use of perioperative probiotics or synbiotics reduced the risk of postoperative ileus. Probiotics and synbiotics also improved time to first flatus, first defecation, first fluid or solid diet, abdominal distension, and length of postoperative hospital stay ( -1.43 days). The overall quality of evidence was moderate to high.
      </p>
      <p>
        <a href="https://knowintegrativeoncology.org/shared?uuid=ec8229ef-37c4-498a-a7f8-b8441814779a&docType=Microtag" target='_blank' rel='noopener noreferrer'>
          🔒🔗 KNOW Microtag
        </a>
      </p>
      <br/>
      <p>
        Whey Protein:  
        In a systematic review and meta-analysis of 10 trials involving 643 people with cancer, use of whey before and after surgery significantly reduced postoperative complications  (22% in the whey group vs 32% in controls: OR 0.61, 95% CI 0.41–0.90; P = 0.01). Meta-analysis of six trials showed that people who took whey had greater functional walking capacity before surgery and after 4 weeks of surgery. Thirty-day readmissions and LOS showed no differences. Doses were around 1.2 mg/kg/day in six of the trials and the other four used variable amounts. Overall quality of evidence was moderate to low.
      </p>
      <p>
        <a href="https://knowintegrativeoncology.org/shared?uuid=1cf7786a-8331-4f1f-8f43-b30215355c40&docType=None" target='_blank' rel='noopener noreferrer'>
          🔒🔗 KNOW Summary
        </a>
      </p>

      <h2 style={h2Style}>Cardiotoxicity</h2>
      <p>
        Omega 3 fatty acids: In an RCT, the 30 out of 60 children who took 1000mg/day of 
        omega 3 fatty acids in addition to doxorubicin chemotherapy had better heart function than controls (preserved systolic function, left ventricular function and improved cardiac markers troponin I, CK-MB, and NT-proBNP). 
        No adverse effects were observed due to omega-3 fatty acid supplementation.
      </p>
      <p>
        <a href="https://knowintegrativeoncology.org/shared?uuid=c451f204-cc05-4177-af1b-1cf3ae488190&docType=None" target='_blank' rel='noopener noreferrer'>
          🔒🔗 KNOW Summary
        </a>
      </p>
      <br/>
      <p>
        Ginseng: 
        In a double blind RCT, the 15 out of 30 women who took 
        ginseng (1 g/day) alongside 
        anthracycline based chemotherapy for breast cancer had better heart function (left ventricular ejection fraction after the eighth chemotherapy cycle had 
        improved from baseline whereas the placebo group had decreased ejection fraction). 6 patients in the placebo group developed cancer-related cardiac dysfunction whereas none of the patients in the ginseng group did.
      </p>
      <p>
        <a href="https://knowintegrativeoncology.org/shared?uuid=a138d013-2d51-42a3-bc2d-6b3b35ced3a0&docType=None" target='_blank' rel='noopener noreferrer'>
          🔒🔗 KNOW Summary
        </a>
      </p>

      <h2 style={h2Style}>Insomnia</h2>
      <p>
        In a systematic review and meta-analysis of 
        933 people 
        with cancer from 10 RCT’s, 
        aromatherapy significantly improved sleep quality [SMD=−0.79, 95% CI (−0.93, −0.66), p&lt;0.01]. The effect was especially strong for single vs compound essential oil use, people with breast cancer, and use of lavender essential oil. Nine trials used inhalation while one trial used massage. Frequency of treatment was 1-7 times per week all dosed at night. The dosage of aroma oils was 2-8 drops per time. Three of the 10 RCTs reported adverse events, of which only one RCT indicated that patients had headache and sneezing. Overall risk of bias was moderate.
      </p>
      <p>
        <a href="https://knowintegrativeoncology.org/shared?uuid=5ec1c4af-7f0d-4bf4-a191-a8078bb97411&docType=Microtag" target='_blank' rel='noopener noreferrer'>
          🔒🔗 KNOW Microtag
        </a> 
      </p>

      <h2 style={h2Style}>Curcumin Drug Interactions with Chemotherapy</h2>
      <p>
        In a pilot study, 60 women undergoing paclitaxel chemotherapy for stage 3  breast cancer who took turmeric 2g daily for 21 days had significant improvements in global health status, symptom scores (fatigue, nausea, vomiting, pain, appetite loss, insomnia, constipation, diarrhea), and hematological parameters (Hg, WBC, neutrophils).  
      </p>
      <p>
        <a href="https://knowintegrativeoncology.org/shared?uuid=90d34ff5-a773-4496-b0fb-d38c3f32211c&docType=Microtag" target='_blank' rel='noopener noreferrer'>
          🔒🔗 KNOW Microtag
        </a>
      </p>
      <br/>
      <p>
        In a pharmacokinetic study of 60 people undergoing paclitaxel chemotherapy, the addition of 2 g of plain turmeric daily caused minor reductions in blood drug levels (7.7% less area under the curve and 12.1% less maximum drug concentrations). The observed changes were deemed not clinically relevant using the standard “no effect boundary” of 80%–125%.
      </p>
      <p>
        <a href="https://knowintegrativeoncology.org/shared?uuid=b85f41ab-353c-4c25-aeb0-ac555eab7f5f&docType=Microtag" target='_blank' rel='noopener noreferrer'>
          🔒🔗 KNOW Summary
        </a>
      </p>
      <br/>
      <p>
        In a phase I study, 10 people with advanced cancers were given an oral curcumin 
        phosphatidylcholine complex alongside irinotecan chemotherapy. Curcumin did not significantly alter the pharmacokinetics of irinotecan and its metabolites. There was no increase in irinotecan-associated toxicities with the addition of curcumin and no dose-related toxicity, even at the highest dose of 4 g.
      </p>
      <p>
        <a href="https://knowintegrativeoncology.org/shared?uuid=eacf29c8-aa1e-4c9a-808a-20cb5ded3f02&docType=Microtag" target='_blank' rel='noopener noreferrer'>
          🔒🔗 KNOW Microtag
        </a>
      </p>
      <br/>
      <p>
        In a pilot study with 33 patients with multiple myeloma who were treated with melphalan and prednisone, those who received 8 g of oral curcumin over 4 cycles of treatment had improved remission rates and improvements in transcription factors compared to controls.
      </p>
      <p>
        <a href="https://knowintegrativeoncology.org/shared?uuid=00938cdc-4613-43af-b5cc-c222a89f8c09&docType=Summary" target='_blank' rel='noopener noreferrer'>
          🔒🔗 KNOW Summary
        </a>
      </p>

      <h2 style={h2Style}>Treating the Terrain</h2>
      <p>
        Vitamin D3: In a systematic review and meta-analysis of people with cancer from 
        11 studies (5 RCTs, 6 observational cohort studies), post diagnosis vitamin D supplement use was associated with a 13% improved overall survival (9 studies, RR 
        0.87 (95% CI, 0.78–0.98; p = 0.02; I 2 = 0%). However, vitamin D use did not statistically impact progression-free survival (8 studies), cancer specific survival (3 studies), or relapse (3 studies). 
        Vitamin D impact on overall survival or progression-free survival were more apparent in studies assessing vitamin D supplements during cancer treatment rather than after treatment. RCT evidence was insufficient to establish cause-and-effect relationships.
      </p>
      <p>
        <a href="https://knowintegrativeoncology.org/shared?uuid=7bbad8f6-de87-4076-b9f3-c56e71b637b5&docType=Microtag" target='_blank' rel='noopener noreferrer'>
          🔒🔗 KNOW Microtag
        </a>
      </p>
      <br/>
      <p>
        Fish oil: 
        In a systematic review and meta-analysis of 1184 people from 12 randomized controlled trials, omega 3 fatty acids improved quality of life and survival but failed to improve body weight/cachexia. The use of n-3 PUFAs was associated with a significant improvement in quality of life (SMD, 0.70; 95% CI, 0.01-1.40; 
        P = .048) and median duration of survival (median survival ratio, 1.10; 95% CI, 1.02-1.19; 
        P = .014).
      </p>
      <p>
        <a href="https://knowintegrativeoncology.org/shared?uuid=5be76a10-1cea-40f1-8acf-4844c6b4aa5f&docType=None" target='_blank' rel='noopener noreferrer'>
          🔒🔗 KNOW Summary
        </a>
      </p>
    </div>
  );
}